import React, { useState, useCallback, useContext } from 'react';
import { ImageViewerContext } from '../../Context/ImageViewerContext';
import BackendDataContext from "../../Context/BackendDataContext";

import './ImageGallery.scss';
import { useWindowSize } from '../../Utils/useWindowSize';

const ImageGallery = ({images}) => {

    const { setImageViewerOpen, setImageViewerConfig, imageViewerConfig } = useContext(ImageViewerContext);
    const windowSize = useWindowSize();
    
    const photoList = images.map((image)=>{
        let fullImagePath = "https://api.moderni-zelesice.cz" + image;
        return fullImagePath;
    });

    const getRowStyling = (length) => {
        let remainderRows = 0;
        let rowNumber = 0;
        switch (length % 7) {
            case 1:
            case 2:
                remainderRows = 4;
                break;
            case 3:
                remainderRows = 9;
            case 4:
            case 5:
            case 6:
                remainderRows = 15;
                break;
            case 0:
                remainderRows = 0;
                break;
            default:
                remainderRows = 0;
        }
        rowNumber = (length - length % 7) / 7 * 20 + remainderRows;
        if (windowSize.width < 1200) {
            return {gridTemplateRows: `repeat(${rowNumber}, minmax(8vw, 20px))`};
        } else return {};
    }

    const rowStyleObject = getRowStyling(photoList.length);

    const openPopUp = (index) => {
        setImageViewerConfig({images: [...photoList], currentImage: index});
        setImageViewerOpen(true);
    }

    return (
        <div className="image-gallery-container">
            <ul style={rowStyleObject}>
                {photoList.map((photo, index)=>{
                    let altProp = photo.split("/")[photo.split("/").length-1].split(".")[0];
                    return <li><div className="gallery-single-img-wrapper" onClick={()=>openPopUp(index)}><img src={photo} alt={altProp} loading="lazy"></img></div></li>               
                    })}
            </ul>
        </div>
    );
}

export default ImageGallery;