export const navigationItems = [
    {
        title: 'Domů',
        link: '/',
        component: "Home",
    },
    {
        title: 'O projektu',
        link: '/o-projektu',
        component: "AboutProject",
    },
    {
        title: 'Lokalita',
        link: '/lokalita',
        component: "Locality",
    },
    {
        title: "Nabídka domů",
        link: "/nabidka-domu",
        component: "OfferedHouses",
    },
    {
        title: "Aktuality",
        link: "/aktuality",
        component: "News",
    },
    {
        title: "Kontakt",
        link: "/kontakt",
        component: "Contact",
    },
];