import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
/* Containers, Components and Utils */
import Tooltip from '../Tooltip/Tooltip';
/* Styles */
import './ImageMap.scss';


const ImageMap = ({houses}) => {
    
    /**
     * If hover on tooltip the area should stay highlighted with opacity 0.7
     */
    const [firstOpacity, setFirstOpacity] = useState('0');
    const [secondOpacity, setSecondOpacity] = useState('0');
    const [thirdOpacity, setThirdOpacity] = useState('0');
    const [fourthOpacity, setFourthOpacity] = useState('0');
    const [fifthOpacity, setFifthOpacity] = useState('0');
    const [sixthOpacity, setSixthOpacity] = useState('0');
    const [seventhOpacity, setSeventhOpacity] = useState('0');
    const [eighthOpacity, setEighthOpacity] = useState('0');
    const [ninthOpacity, setNinthOpacity] = useState('0');
    const [tenthOpacity, setTenthOpacity] = useState('0');
    const [eleventhOpacity, setEleventhOpacity] = useState('0');
    const [twelthOpacity, setTwelthOpacity] = useState('0');
    const [thirteenthOpacity, setThirteenthOpacity] = useState('0');
    

    const history = useHistory();
    const handleResponsivity = useMediaQuery({
        query: `(min-width: 770px)`
    });

    let imageMap = "";
    if (houses && houses.length !== 0){
        let newHousesArray = [...houses];

        imageMap = (
            <div className="image-map">
                <svg className="image-map-content" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 675">
                    {/** FIRST HOUSE **/}
                    {!newHousesArray[0].available
                    ?
                        <g className="image-map1 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="194.78 497.08 186.98 438.15 217.02 434.25 212.89 389.77 299.8 379.22 306.68 398.02 307.13 404.22 308.97 403.76 316.76 424.17 321.81 484.93 280.54 492.04 278.7 484.47 194.78 497.08"/>
                            <text x="230" y="450" fontSize="20px" fill="white" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="1" onClick={handleResponsivity ? () => history.push('/detail-domu/1') : undefined}>
                            <g className="image-map1" fill="#F5CE42" stroke="#F5CE42" strokeWidth="0px" opacity={firstOpacity}>
                                <polygon class="cls-1" points="194.78 497.08 186.98 438.15 217.02 434.25 212.89 389.77 299.8 379.22 306.68 398.02 307.13 404.22 308.97 403.76 316.76 424.17 321.81 484.93 280.54 492.04 278.7 484.47 194.78 497.08"/>
                            </g>
                        </a>
                    }
                    {/** SECOND HOUSE **/}
                    {!newHousesArray[1].available
                    ?
                        <g className="image-map2 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="365.83 475.99 353.45 446.41 352.54 420.04 374.09 417.29 372.48 397.34 374.09 396.88 372.94 374.18 453.66 364.78 465.58 381.52 465.81 387.48 468.56 387.25 482.78 406.28 484.84 468.42 447.46 474.38 442.65 465.21 365.83 475.99"/>
                            <text x="395" y="435" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="2" onClick={handleResponsivity ? () => history.push('/detail-domu/2') : undefined}>
                            <g className="image-map2" fill="#F5CE42" opacity={secondOpacity*0.7}>
                                <polygon class="cls-1" points="365.83 475.99 353.45 446.41 352.54 420.04 374.09 417.29 372.48 397.34 374.09 396.88 372.94 374.18 453.66 364.78 465.58 381.52 465.81 387.48 468.56 387.25 482.78 406.28 484.84 468.42 447.46 474.38 442.65 465.21 365.83 475.99"/>
                            </g>
                        </a>
                    }
                    {/** THIRD HOUSE **/}
                    {!newHousesArray[2].available
                    ?
                        <g className="image-map3 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="525.88 462.46 504.79 432.42 504.79 410.64 519.01 408.57 518.09 389.08 521.07 388.62 520.61 366.61 594.45 357.44 611.87 374.18 611.64 379.45 615.77 378.76 634.8 397.11 634.57 454.2 598.8 460.39 593.07 452.6 525.88 462.46"/>
                            <text x="545" y="420" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="3" onClick={handleResponsivity ? () => history.push('/detail-domu/3') : undefined}>
                            <g className="image-map3" fill="#F5CE42" opacity={thirdOpacity*0.7}>
                                <polygon class="cls-1" points="525.88 462.46 504.79 432.42 504.79 410.64 519.01 408.57 518.09 389.08 521.07 388.62 520.61 366.61 594.45 357.44 611.87 374.18 611.64 379.45 615.77 378.76 634.8 397.11 634.57 454.2 598.8 460.39 593.07 452.6 525.88 462.46"/>
                            </g>
                        </a>
                    }
                    {/** FOURTH HOUSE **/}
                    {!newHousesArray[3].available
                    ?
                        <g className="image-map4 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="671.49 446.64 644.66 420.04 645.12 398.02 652.92 396.88 652.92 377.69 656.66 377.62 656.89 356.6 724.92 347.35 746.01 362.94 745.78 366.84 747.69 368.22 752.43 367.83 774.45 384.73 771.77 440.29 740.13 445.72 732.33 438.46 672.87 448.7 671.49 446.64"/>
                            <text x="680" y="410" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="4" onClick={handleResponsivity ? () => history.push('/detail-domu/4') : undefined}>
                            <g className="image-map4" fill="#F5CE42" opacity={fourthOpacity*0.7}>
                                <polygon class="cls-1" points="671.49 446.64 644.66 420.04 645.12 398.02 652.92 396.88 652.92 377.69 656.66 377.62 656.89 356.6 724.92 347.35 746.01 362.94 745.78 366.84 747.69 368.22 752.43 367.83 774.45 384.73 771.77 440.29 740.13 445.72 732.33 438.46 672.87 448.7 671.49 446.64"/>
                            </g>
                        </a>
                    }
                    {/** FIFTH HOUSE **/}
                    {!newHousesArray[4].available
                    ?
                        <g className="image-map5 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="773.99 407.66 774.9 387.02 776.85 386.68 777.66 367.64 782.36 367.53 783.39 347.35 847.71 338.98 870.52 353.31 870.41 356.64 873.04 358.47 877.75 358.24 903.31 373.83 898.84 427.49 869.72 432.65 860.32 425.88 808.5 435.75 773.99 407.66"/>
                            <text x="810" y="395" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="5" onClick={handleResponsivity ? () => history.push('/detail-domu/5') : undefined}>
                            <g className="image-map5" fill="#F5CE42" opacity={fifthOpacity*0.7}>
                                <polygon class="cls-1" points="773.99 407.66 774.9 387.02 776.85 386.68 777.66 367.64 782.36 367.53 783.39 347.35 847.71 338.98 870.52 353.31 870.41 356.64 873.04 358.47 877.75 358.24 903.31 373.83 898.84 427.49 869.72 432.65 860.32 425.88 808.5 435.75 773.99 407.66"/>
                            </g>
                        </a>
                    }
                    {/** SIXTH HOUSE **/}
                    {!newHousesArray[5].available
                    ?
                        <g className="image-map6 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="979.21 410.98 927.85 419.92 901.13 401.92 903.31 373.83 892.31 367.19 893.11 355.49 899.53 355.03 900.33 334.97 960.87 327.4 986.55 341.39 986.32 344.14 990.1 346.09 994.57 345.75 1022.78 360.65 1016.82 413.16 989.18 417.4 979.21 410.98"/>
                            <text x="930" y="385" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="6" onClick={handleResponsivity ? () => history.push('/detail-domu/6') : undefined}>
                            <g className="image-map6" fill="#F5CE42" opacity={sixthOpacity*0.7}>
                                <polygon class="cls-1" points="979.21 410.98 927.85 419.92 901.13 401.92 903.31 373.83 892.31 367.19 893.11 355.49 899.53 355.03 900.33 334.97 960.87 327.4 986.55 341.39 986.32 344.14 990.1 346.09 994.57 345.75 1022.78 360.65 1016.82 413.16 989.18 417.4 979.21 410.98"/>
                            </g>
                        </a>
                    }
                    {/** SEVENTH HOUSE **/}
                    {!newHousesArray[6].available
                    ?
                        <g className="image-map7 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="1127.34 393.32 1102.12 396.08 1101.54 399.63 1091.45 394.13 1037.68 403.41 1019.11 393.1 1022.78 360.65 1002.14 349.64 1003.06 340.81 1009.02 340.24 1010.97 320.98 1066.92 313.64 1095.12 326.37 1095.01 328.78 1100.05 330.95 1104.18 330.5 1134.79 344.48 1127.34 393.32"/>
                            <text x="1040" y="370" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="7" onClick={handleResponsivity ? () => history.push('/detail-domu/7') : undefined}>
                            <g className="image-map7" fill="#F5CE42" opacity={seventhOpacity*0.7}>
                                <polygon class="cls-1" points="1127.34 393.32 1102.12 396.08 1101.54 399.63 1091.45 394.13 1037.68 403.41 1019.11 393.1 1022.78 360.65 1002.14 349.64 1003.06 340.81 1009.02 340.24 1010.97 320.98 1066.92 313.64 1095.12 326.37 1095.01 328.78 1100.05 330.95 1104.18 330.5 1134.79 344.48 1127.34 393.32"/>
                            </g>
                        </a>
                    }
                    {/** EIGHTH HOUSE **/}
                    {!newHousesArray[7].available
                    ?
                        <g className="image-map8 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="709.78 334.62 689.61 318.92 690.52 300.57 688.92 299.43 689.03 293.12 706.34 291.29 706.46 281.54 707.95 281.43 708.06 277.3 706.57 276.27 706.57 267.9 803.11 257.81 833.15 274.89 830.62 317.66 829.59 317.43 829.82 319.26 785.57 324.99 781.67 322.36 758.97 325.11 759.08 326.71 758.17 326.71 758.17 328.2 709.78 334.62"/>
                            <text x="740" y="305" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="8" onClick={handleResponsivity ? () => history.push('/detail-domu/8') : undefined}>
                            <g className="image-map8" fill="#F5CE42" opacity={eighthOpacity*0.7}>
                                <polygon class="cls-1" points="709.78 334.62 689.61 318.92 690.52 300.57 688.92 299.43 689.03 293.12 706.34 291.29 706.46 281.54 707.95 281.43 708.06 277.3 706.57 276.27 706.57 267.9 803.11 257.81 833.15 274.89 830.62 317.66 829.59 317.43 829.82 319.26 785.57 324.99 781.67 322.36 758.97 325.11 759.08 326.71 758.17 326.71 758.17 328.2 709.78 334.62"/>
                            </g>
                        </a>
                    }
                    {/** NINTH HOUSE **/}
                    {!newHousesArray[8].available
                    ?
                        <g className="image-map9 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="845.64 288.76 843.69 287.39 844.04 281.08 858.6 279.82 858.6 269.73 861.12 269.96 861.01 266.29 859.4 265.49 859.63 257.81 948.02 248.29 982.88 263.77 978.64 306.42 937.48 312.15 934.04 309.75 913.52 311.69 913.4 313.07 912.14 313.19 912.25 314.9 888.87 317.66 889.1 318.92 868.23 321.21 844.27 306.88 845.64 288.76"/>    
                            <text x="890" y="290" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="9" onClick={handleResponsivity ? () => history.push('/detail-domu/9') : undefined}>
                            <g className="image-map9" fill="#F5CE42" opacity={ninthOpacity*0.7}>
                                <polygon class="cls-1" points="845.64 288.76 843.69 287.39 844.04 281.08 858.6 279.82 858.6 269.73 861.12 269.96 861.01 266.29 859.4 265.49 859.63 257.81 948.02 248.29 982.88 263.77 978.64 306.42 937.48 312.15 934.04 309.75 913.52 311.69 913.4 313.07 912.14 313.19 912.25 314.9 888.87 317.66 889.1 318.92 868.23 321.21 844.27 306.88 845.64 288.76"/>    
                            </g>
                        </a>
                    }
                    {/** TENTH HOUSE **/}
                    {!newHousesArray[9].available
                    ?
                        <g className="image-map10 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="558.67 269.96 558.45 248.52 578.51 246.57 578.28 229.38 668.74 221.01 689.95 234.65 689.15 273.63 618.41 281.66 614.17 278.45 572.2 282.8 558.67 269.96"/>
                            <text x="600" y="260" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="10" onClick={handleResponsivity ? () => history.push('/detail-domu/10') : undefined}>
                            <g className="image-map10" fill="#F5CE42" opacity={tenthOpacity*0.7}>
                                <polygon class="cls-1" points="558.67 269.96 558.45 248.52 578.51 246.57 578.28 229.38 668.74 221.01 689.95 234.65 689.15 273.63 618.41 281.66 614.17 278.45 572.2 282.8 558.67 269.96"/>
                            </g>
                        </a>
                    }
                    {/** ELEVENTH HOUSE **/}
                    {!newHousesArray[10].available
                    ?
                        <g className="image-map11 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="696.83 241.64 715.29 239.58 715.75 222.84 739.36 221.01 741.43 222.73 762.98 220.55 761.03 218.71 801.04 215.39 825.12 228.46 822.94 265.26 816.52 265.72 803.11 257.81 707.26 268.47 696.14 261.13 696.83 241.64"/>
                            <text x="735" y="250" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="11" onClick={handleResponsivity ? () => history.push('/detail-domu/11') : undefined}>
                            <g className="image-map11" fill="#F5CE42" opacity={eleventhOpacity*0.7}>
                                <polygon class="cls-1" points="696.83 241.64 715.29 239.58 715.75 222.84 739.36 221.01 741.43 222.73 762.98 220.55 761.03 218.71 801.04 215.39 825.12 228.46 822.94 265.26 816.52 265.72 803.11 257.81 707.26 268.47 696.14 261.13 696.83 241.64"/>
                            </g>
                        </a>
                    }
                    {/** TWELTH HOUSE C1 **/}
                    {!newHousesArray[10].available
                    ?
                        <g className="image-map11 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="29.45 512.9 29.91 508.32 19.36 447.32 17.3 447.78 26.24 388.62 119.11 377.39 120.94 390.46 122.32 390.23 126.22 417.98 127.13 445.72 125.38 445.64 127.97 466.51 126.67 466.81 127.97 476.98 128.36 492.95 128.59 497.85 29.45 512.9"/>
                            <text x="35" y="470" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="12" onClick={handleResponsivity ? () => history.push('/detail-domu/12') : undefined}>
                            <g className="image-map11" fill="#F5CE42" opacity={twelthOpacity*0.7}>
                                <polygon class="cls-1" points="29.45 512.9 29.91 508.32 19.36 447.32 17.3 447.78 26.24 388.62 119.11 377.39 120.94 390.46 122.32 390.23 126.22 417.98 127.13 445.72 125.38 445.64 127.97 466.51 126.67 466.81 127.97 476.98 128.36 492.95 128.59 497.85 29.45 512.9"/>
                            </g>
                        </a>
                    }
                    {/** 13th HOUSE C2 **/}
                    {!newHousesArray[10].available
                    ?
                        <g className="image-map11 sold" fill="#484848" opacity="0">
                            <polygon class="cls-1" points="1046.62 269.96 1048.46 255.63 1045.82 254.71 1046.74 248.52 1055.11 247.83 1055.45 245.66 1053.96 245.2 1054.65 238.32 1099.25 233.27 1117.02 239.81 1116.9 241.99 1129.74 246.8 1134.9 246.23 1169.07 258.5 1162.99 298.05 1115.3 305.05 1064.51 285.1 1065.31 277.3 1046.62 269.96"/>
                            <text x="1075" y="285" fill="white" fontSize="20px" scaleToFit={true} verticalAnchor="start">Prodáno</text>
                        </g>
                    :
                        <a data-tip data-for="13" onClick={handleResponsivity ? () => history.push('/detail-domu/13') : undefined}>
                            <g className="image-map11" fill="#F5CE42" opacity={thirteenthOpacity*0.7}>
                                <polygon class="cls-1" points="1046.62 269.96 1048.46 255.63 1045.82 254.71 1046.74 248.52 1055.11 247.83 1055.45 245.66 1053.96 245.2 1054.65 238.32 1099.25 233.27 1117.02 239.81 1116.9 241.99 1129.74 246.8 1134.9 246.23 1169.07 258.5 1162.99 298.05 1115.3 305.05 1064.51 285.1 1065.31 277.3 1046.62 269.96"/>
                            </g>
                        </a>
                    }
                </svg>

                {/** FIRST HOUSE **/}
                {newHousesArray[0].available && 
                    <Tooltip 
                        houseNum={newHousesArray[0].name.toString()} 
                        id="1"
                        disposition={newHousesArray[0].disposition} 
                        floorArea={newHousesArray[0].floor_area}
                        lotArea={newHousesArray[0].lot_area}
                        price={newHousesArray[0].price}
                        mouseEnter={() => setFirstOpacity('1')} 
                        mouseLeave={() => setFirstOpacity('0')}
                    />
                }
                {/** SECOND HOUSE **/}
                {newHousesArray[1].available && 
                    <Tooltip 
                        houseNum={newHousesArray[1].name.toString()} 
                        id="2"
                        disposition={newHousesArray[1].disposition} 
                        floorArea={newHousesArray[1].floor_area}
                        lotArea={newHousesArray[1].lot_area} 
                        price={newHousesArray[1].price} 
                        mouseEnter={() => setSecondOpacity('1')} 
                        mouseLeave={() => setSecondOpacity('0')}
                    />
                }
                {/** THIRD HOUSE **/}
                {newHousesArray[2].available && 
                    <Tooltip 
                        houseNum={newHousesArray[2].name.toString()} 
                        id="3"
                        disposition={newHousesArray[2].disposition} 
                        floorArea={newHousesArray[2].floor_area}
                        lotArea={newHousesArray[2].lot_area} 
                        price={newHousesArray[2].price} 
                        mouseEnter={() => setThirdOpacity('1')} 
                        mouseLeave={() => setThirdOpacity('0')}
                    />
                }
                {/** FOURTH HOUSE **/}
                {newHousesArray[3].available && 
                    <Tooltip 
                        houseNum={newHousesArray[3].name.toString()} 
                        id="4"
                        disposition={newHousesArray[3].disposition} 
                        floorArea={newHousesArray[3].floor_area}
                        lotArea={newHousesArray[3].lot_area} 
                        price={newHousesArray[3].price} 
                        mouseEnter={() => setFourthOpacity('1')} 
                        mouseLeave={() => setFourthOpacity('0')}
                    />
                }
                {/** FIFTH HOUSE **/}
                {newHousesArray[4].available && 
                    <Tooltip 
                        houseNum={newHousesArray[4].name.toString()} 
                        id="5"
                        disposition={newHousesArray[4].disposition} 
                        floorArea={newHousesArray[4].floor_area}
                        lotArea={newHousesArray[4].lot_area} 
                        price={newHousesArray[4].price} 
                        mouseEnter={() => setFifthOpacity('1')} 
                        mouseLeave={() => setFifthOpacity('0')}
                    />
                }
                {/** SIXTH HOUSE **/}
                {newHousesArray[5].available && 
                    <Tooltip 
                        houseNum={newHousesArray[5].name.toString()} 
                        id="6"
                        disposition={newHousesArray[5].disposition} 
                        floorArea={newHousesArray[5].floor_area}
                        lotArea={newHousesArray[5].lot_area} 
                        price={newHousesArray[5].price} 
                        mouseEnter={() => setSixthOpacity('1')} 
                        mouseLeave={() => setSixthOpacity('0')}
                    />
                }
                {/** SEVENTH HOUSE **/}
                {newHousesArray[6].available && 
                    <Tooltip 
                        houseNum={newHousesArray[6].name.toString()} 
                        id="7"
                        disposition={newHousesArray[6].disposition} 
                        floorArea={newHousesArray[6].floor_area}
                        lotArea={newHousesArray[6].lot_area}
                        price={newHousesArray[6].price} 
                        mouseEnter={() => setSeventhOpacity('1')} 
                        mouseLeave={() => setSeventhOpacity('0')}
                    />
                }
                {/** EIGHTH HOUSE **/}
                {newHousesArray[7].available && 
                    <Tooltip 
                        houseNum={newHousesArray[7].name.toString()} 
                        id="8"
                        disposition={newHousesArray[7].disposition} 
                        floorArea={newHousesArray[7].floor_area}
                        lotArea={newHousesArray[7].lot_area}
                        price={newHousesArray[7].price} 
                        mouseEnter={() => setEighthOpacity('1')} 
                        mouseLeave={() => setEighthOpacity('0')}
                    />
                }
                {/** NINTH HOUSE **/}
                {newHousesArray[8].available && 
                    <Tooltip 
                        houseNum={newHousesArray[8].name.toString()} 
                        id="9"
                        disposition={newHousesArray[8].disposition} 
                        floorArea={newHousesArray[8].floor_area}
                        lotArea={newHousesArray[8].lot_area}
                        price={newHousesArray[8].price} 
                        mouseEnter={() => setNinthOpacity('1')} 
                        mouseLeave={() => setNinthOpacity('0')}
                    />
                }
                {/** TENTH HOUSE **/}
                {newHousesArray[9].available && 
                    <Tooltip 
                        houseNum={newHousesArray[9].name.toString()} 
                        id="10"
                        disposition={newHousesArray[9].disposition} 
                        floorArea={newHousesArray[9].floor_area}
                        lotArea={newHousesArray[9].lot_area}
                        price={newHousesArray[9].price} 
                        mouseEnter={() => setTenthOpacity('1')} 
                        mouseLeave={() => setTenthOpacity('0')}
                    />
                }
                {/** ELEVENTH HOUSE **/}
                {newHousesArray[10].available && 
                    <Tooltip 
                        houseNum={newHousesArray[10].name.toString()} 
                        id="11"
                        disposition={newHousesArray[10].disposition} 
                        floorArea={newHousesArray[10].floor_area}
                        lotArea={newHousesArray[10].lot_area}
                        price={newHousesArray[10].price} 
                        mouseEnter={() => setEleventhOpacity('1')} 
                        mouseLeave={() => setEleventhOpacity('0')}
                    />
                }
                {/** TWELTH HOUSE **/}
                {newHousesArray[11].available && 
                    <Tooltip 
                        houseNum={newHousesArray[11].name.toString()} 
                        id="12"
                        disposition={newHousesArray[11].disposition} 
                        floorArea={newHousesArray[11].floor_area}
                        lotArea={newHousesArray[11].lot_area}
                        price={newHousesArray[11].price} 
                        mouseEnter={() => setTwelthOpacity('1')} 
                        mouseLeave={() => setTwelthOpacity('0')}
                    />
                }
                {/** THIRTEENTH HOUSE **/}
                {newHousesArray[12].available && 
                    <Tooltip 
                        houseNum={newHousesArray[12].name.toString()} 
                        id="13"
                        disposition={newHousesArray[12].disposition} 
                        floorArea={newHousesArray[12].floor_area}
                        lotArea={newHousesArray[12].lot_area}
                        price={newHousesArray[12].price} 
                        mouseEnter={() => setThirteenthOpacity('1')} 
                        mouseLeave={() => setThirteenthOpacity('0')}
                    />
                }
            </div>
        );
    }


    return imageMap;
};

export default ImageMap;