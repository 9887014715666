import React from 'react';
import Table from './Table/Table';
import visualMap from '../../assets/img/Components/InteractiveHouses/interactive_houses_background.jpg'
import { useWindowSize } from '../../Utils/useWindowSize';

const MobileHouseChoice = ({houses}) => {

    const windowSize = useWindowSize();

    const columns = React.useMemo(
        () => [
            {
                Header: 'Název domu',
                accessor: 'name',
            },
            {
                Header: 'Dostupnost',
                accessor: 'available',
            },
            {
                Header: 'Dispozice',
                accessor: 'disposition',
            },
            {
                Header: 'Rozloha domu',
                accessor: 'floor_area',
            },
            {
                Header: 'Rozloha pozemku',
                accessor: 'lot_area',
            },
            {
                Header: 'Cena',
                accessor: 'price',
            }
        ],
        []
      )

    let data = [...houses];
    data = data.map((house)=>{
        let newHouse = {...house};
        newHouse.available = house.available ? "K dispozici" : "Prodáno";
        return newHouse;
    });

    return (
        <>
        {windowSize.width <= 769 &&
        <img src={visualMap} alt="vizualizace všech domů" width="100%" loading="lazy"/>
        }
        {(houses && houses.length !== 0) ?
        <div style={{position: 'relative', marginTop: '1rem'}}>
            <Table columns={columns} data={data}/>
        </div> : null}
        </>
    );
}

export default MobileHouseChoice;