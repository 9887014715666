import React, { useState } from 'react';
import TextEditorText from '../../TextEditorText/TextEditorText';
import obj from './obj.json';

/*SASS*/
import './BlogPost.scss';

const BlogPost = ({blogpost}) => {

    return (
        <article className="blogpost">
            <div className="article-content">
                <h2>{blogpost.title}</h2>
                <div className="article-text">
                    <TextEditorText data={JSON.parse(blogpost.content)} />
                </div>
            </div>
            <div className="article-images-container">
                {blogpost.images.map((image)=>{
                    let src = "https://api.moderni-zelesice.cz" + image;
                    return <img src={src} alt={image.split("/media/")[1]}/>
                })}
            </div>
        </article>
    );
}
export default BlogPost;