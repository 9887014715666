import React, { useContext } from 'react';

/*OWN*/
/*import MapContainer from './MapComponent';*/
import map from '../../../assets/img/locality_map.jpg';
import { useWindowSize } from '../../../Utils/useWindowSize';
import car from '../../../assets/img/icons/car.svg';
import trees from '../../../assets/img/icons/trees.svg';
import house from '../../../assets/img/icons/house.png';
import BackendDataContext from '../../../Context/BackendDataContext';

/*SASS*/
import './LocalityPage.scss';
import TextEditorText from '../../TextEditorText/TextEditorText';


const LocalityPage = (props) => {

    {/*{ title, content, img } = props;*/}

    const { currentState } = useContext(BackendDataContext);
    const page = currentState.pages.find((page)=>page.id === 3);

    const windowSize = useWindowSize();
    
    return (
        <>
        {page &&
        <section className="locality-page-container">
            <div className="locality-description-wrapper">
                <TextEditorText data={JSON.parse(page.sections[0].content)}></TextEditorText>
                {windowSize.width > 769 &&
                
                    <div className="map-image-container">
                        <a href="https://www.google.com/maps/place/664+43+%C5%BDele%C5%A1ice/@49.1140467,16.5380637,13z/data=!3m1!4b1!4m5!3m4!1s0x4712bfac6b74d82b:0x400af0f66163950!8m2!3d49.1168862!4d16.5813788">
                            <img src={map} alt="Mapa okoli zelesic" loading="lazy"></img>
                        </a>
                    </div>
                }
            </div>
            <div className="locality-features-wrapper">
                {/*<MapContainer />*/}
                <h2>Čím si vás Želešice získají</h2>
                <div className="locality-features-content">
                    <div className="locality-features-individual car-features">
                        <div className="feature-img-container">
                            <img src={car} alt="ikona auta"></img>
                        </div>
                        <ul className="bullet-list">
                            <li className="bullet-feature">bydlení vzdálené 15 minut od centra Brna </li>
                            <li className="bullet-feature">8 minut vzdálené obchodní a zábavní centrum OC Olympia</li>
                            <li className="bullet-feature">dopravní dostupnost</li>
                        </ul>
                    </div>
                    <div className="locality-features-individual trees-features">
                        <div className="feature-img-container">
                            <img src={trees} alt="ikona stromů"></img>
                        </div>
                        <ul className="bullet-list">
                            <li className="bullet-feature">výhody města s vlastní zahradou</li>
                            <li className="bullet-feature">malebná příroda a relaxace</li>
                        </ul>
                    </div>
                    <div className="locality-features-individual house-features">
                        <div className="feature-img-container">
                            <img src={house} alt="ikona domu"></img>
                        </div>
                        <ul className="bullet-list">
                            <li className="bullet-feature">dobrá občanská vybavenost</li>
                            <li className="bullet-feature">mateřské a základní školy</li>
                            <li className="bullet-feature">sportovní vyžití</li>
                        </ul>
                    </div>
                </div>
            </div>
            {windowSize.width <= 769 &&
                <a href="https://www.google.com/maps/place/664+43+%C5%BDele%C5%A1ice/@49.1140467,16.5380637,13z/data=!3m1!4b1!4m5!3m4!1s0x4712bfac6b74d82b:0x400af0f66163950!8m2!3d49.1168862!4d16.5813788">
                    <div className="map-image-container-bottom">
                    </div>
                </a>
            }
        </section> }
        </>
    );
}
export default LocalityPage;