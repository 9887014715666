import React from 'react';
import { Editor, EditorState, convertFromRaw } from "draft-js";

const TextEditorText = ({data}) => {

    const contentState = convertFromRaw(data);
    const editorState = EditorState.createWithContent(contentState);

    return (
      <div className="data-container" style={{fontFamily: 'Nimbus-Regular', lineHeight: '160%'}}>
        <Editor editorState={editorState} readOnly={true} />
      </div>
    );
}

export default TextEditorText;